/* global mixpanel */

export const BATTLEPASS_MP_EVENTS = {
    CHANGED_BACKGROUND: 'changedBackground',
    VIEWED_BATTLEPASS: 'viewedBattlePass',
    CLAIMED_REWARD: 'claimedReward',
    PREMIUM_VIEW: 'premiumView',
    CLICKED_INFO_FOR_PARENTS: 'clickedInfoForParents',
    CLICKED_GETMATHPASS_IN_PREMIUM_VIEW: 'clickedGetMathPassInPremiumView',
    CLICKED_GETMATHPASS_IN_PARENT_INFO_VIEW:
        'clickedGetMathPassInParentInfoView',
    CLICKED_PRICE_OPTION: 'clickedPriceOption',
    CLICKED_GETMATHPASS_IN_PRICE_VIEW: 'clickedGetMathPassInPriceView',
    CLICKED_NO_THANKS_IN_PRICE_VIEW: 'clickedNoThanksInPriceView',
    PAYMENT_CLICKED_BACK: 'paymentClickedBack',
    CLICKED_PAY_BUTTON: 'clickedPayButton',
    SELECTED_PET: 'selectedPet',
    COLLECTED_XP_FROM_PET: 'collectedXpFromPet',
    PET_ACQUIRED: 'petAcquired',
    VIEWED_BP_POPUP_MODAL: 'viewedBPpopupModal',
};

export const BATTLEPASS_MP_METHODS = {
    changedBackground(background) {
        mixpanel.track('Changed Background', {
            'Background ID': background,
        });

        mixpanel.register({
            'Current background': background,
        });
        mixpanel.people.set({
            'Current background': background,
        });
    },
    viewedBattlePass(numberOfClaimable) {
        mixpanel.track('Viewed Battle Pass', {
            'Claimable Rewards Number': numberOfClaimable,
        });
    },
    claimedReward({ level, track, mBucks }) {
        mixpanel.track('Claimed Reward', {
            Level: level,
            Track: track,
            MBucks: mBucks,
        });
        mixpanel.register({
            MBucks: mBucks,
        });
        mixpanel.people.set({
            MBucks: mBucks,
        });
    },
    premiumView(location) {
        mixpanel.track('PremiumView', {
            Location: location,
        });
    },
    clickedInfoForParents() {
        mixpanel.track('Clicked Info For Parents');
    },
    clickedGetMathPassInPremiumView() {
        mixpanel.track('Clicked GetMathPass in Premium View');
    },
    clickedGetMathPassInParentInfoView() {
        mixpanel.track('Clicked GetMathPass in Parent Info View');
    },
    clickedPriceOption(priceName) {
        mixpanel.track('Clicked Price Option', {
            'Price Name': priceName,
        });
    },
    clickedGetMathPassInPriceView(priceName) {
        mixpanel.track('Clicked GetMathPass in Price View', {
            'Price Name Chosen': priceName,
        });
    },
    clickedNoThanksInPriceView() {
        mixpanel.track('Clicked no thanks in Price View');
    },
    paymentClickedBack(location) {
        mixpanel.track('Clicked back in view', {
            Location: location,
        });
    },
    clickedPayButton(provider) {
        if (provider === 'google') {
            mixpanel.track('Clicked Google Pay Button');
        }
        if (provider === 'apple') {
            mixpanel.track('Clicked Apple Pay Button');
        }
        if (provider === 'card') {
            mixpanel.track('Clicked Pay with Card Button');
        }
    },
    selectedPet(petName) {
        mixpanel.track('Selected a pet', {
            'Pet name': petName,
        });
    },
    collectedXpFromPet(xpCollected) {
        mixpanel.track('Collected XP from Pet', {
            'XP collected': xpCollected,
        });
    },
    petAcquired(petName) {
        mixpanel.people.set({
            'Pet name': petName,
        });
    },
    viewedBPpopupModal() {
        mixpanel.track('Viewed At Home BP Popup modal');
    },
};
