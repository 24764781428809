<template>
    <div v-if="isOpen" class="payment-modal-overlay">
        <div class="payment-modal-header">
            <img
                v-if="!unauthorizedAccount && !isParent"
                src="/images/payment-modal-v2/center_header.png"
                alt="header"
                class="payment-modal-center-header"
            />
            <img
                v-if="!unauthorizedAccount"
                src="@/assets/svg/revamp/unsorted/header-logo.svg"
                alt="logo"
                class="payment-modal-logo"
                @click="closeModal"
            />
            <div
                v-if="!unauthorizedAccount"
                class="back-button"
                @click="closeModal"
            >
                <img
                    src="/images/payment-modal-v2/close_icon.png"
                    alt="close"
                />
            </div>
        </div>
        <ParentalGate
            v-if="parentalGatePending && isCapacitorBuild"
            @gatePassed="handleParentalGatePassed"
        />
        <Step2
            v-else-if="
                paymentInfo && $route?.query?.redirect_status === 'failed'
            "
            :product-stripe-info="paymentInfo"
            :discount="showBattlePassCoupon ? discount : null"
            :unauthorized-account="unauthorizedAccount"
            @prevStep="prevStep"
            @closed="closeModal"
        />
        <AlreadySubscribed
            v-else-if="subscribedUser || justSubscribed"
            :unauthorized-account="unauthorizedAccount"
            @closeModal="closeModal"
        />
        <Step1
            v-else-if="step === 1"
            :teacher-info="teacherInfo"
            :unauthorized-user-name="userName"
            :last-student-class="lastStudentClass"
            :price-test-version="priceTestVersion"
            :discount="showBattlePassCoupon ? discount : null"
            :unauthorized-account="unauthorizedAccount"
            @nextStep="nextStep"
            @selectedPlanChange="selectedPlanChange"
        />
        <ParentalGate
            v-if="step === 2 && parentalGatePending"
            @gatePassed="handleParentalGatePassed"
        />
        <Step2
            v-else-if="step === 2"
            :product-stripe-info="selectedProduct?.stripe"
            :discount="showBattlePassCoupon ? discount : null"
            :unauthorized-account="unauthorizedAccount"
            @prevStep="prevStep"
            @closed="closeModal"
        />
    </div>
</template>
<script>
import Step1 from './components/Step1.vue';
import Step2 from './components/Step2.vue';
import ParentalGate from './components/ParentalGate.vue';
import SecureApi from '@/flows/Authentication/services/SecureApi';
import { mapActions, mapGetters } from 'vuex';
import AlreadySubscribed from './components/AlreadySubscribed.vue';
import { fetchAndUpdateUser } from '@/flows/Authentication/helpers/AuthHelpers';
import { inCapacitorBuildEnv, parentalGateEnabled } from '@/core/helpers/utils';
import { revenueCatSubscriptionPurchase } from '@/capacitor/inAppPurchases';
import moment from 'moment';
import { isParent } from '@/flows/Authentication/helpers/AuthHelpers';

export default {
    components: {
        ParentalGate,
        AlreadySubscribed,
        Step1,
        Step2,
    },
    props: {
        isOpen: {
            type: Boolean,
            default: false,
        },
        locationOpened: {
            type: String,
            default: null,
        },
        unauthorizedAccount: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['close'],
    data() {
        return {
            step: 1,
            selectedProduct: null,
            justSubscribed: false,
            lastStudentClass: null,
            teacherInfo: null,
            userName: null,
            priceTestVersion: null,
            parentalGatePending: parentalGateEnabled(),
        };
    },
    computed: {
        ...mapGetters(['user', 'isBefore3PM', 'subscribedUser']),
        isParent() {
            return isParent(this.user);
        },
        isCapacitorBuild() {
            return inCapacitorBuildEnv();
        },
        paymentInfo() {
            return JSON.parse(sessionStorage.getItem('paymentInfo') || 'null');
        },
        discount() {
            return this.user?.paymentInfo?.stripe?.discount;
        },
        showBattlePassCoupon() {
            if (!this.discount) {
                return false;
            }

            const duration = moment
                .duration(
                    moment(this.discount?.redeem_by * 1000).diff(moment()),
                )
                .asMilliseconds();

            return (
                duration > 0 &&
                !['active', 'trialing'].includes(
                    this.user?.subscriptionStatus,
                ) &&
                !this.isBefore3PM
            );
        },
    },
    async mounted() {
        this.$store.dispatch('v2/stripe/initStripe');

        if (!this.unauthorizedAccount) {
            if (!this.user) await fetchAndUpdateUser();

            if (!this.lastStudentClass) {
                const classLists = this.user.studentInfo?.classLists;

                const lastClassCode = classLists
                    ? Object.keys(classLists).pop()
                    : null;

                if (lastClassCode) {
                    const lastClass = await this.getClassPage({
                        classCode: lastClassCode,
                    });

                    if (lastClass) {
                        this.lastStudentClass = lastClass;

                        const teacherInfo = await this.getUsersInfo({
                            userIds: lastClass.teacherId,
                            options: 'accountInfo,teacherInfo',
                        });

                        if (teacherInfo) {
                            this.teacherInfo = teacherInfo[0];
                        }
                    }
                }
            }
        } else {
            this.fetchUserForParent();
        }

        this.$emitter.on(
            'homeGamePaymentModalSubscribed',
            this.subscribedHandler,
        );
    },
    methods: {
        ...mapActions(['getClassPage', 'getUsersInfo', 'sendPixelEvent']),
        resetState() {
            this.step = 1;

            this.selectedProduct = null;

            this.parentalGatePending = parentalGateEnabled();
        },
        closeModal() {
            if (this.isParent && localStorage.getItem('parentSignupFacebook')) {
                localStorage.removeItem('parentSignupFacebook');

                this.$emitter.emit('homeGamePaymentModalClosed');

                this.$emit('close');

                this.resetState();

                return this.$router.push({ name: 'home-game.v10.main-page' });
            }
            if (!this.unauthorizedAccount) {
                this.$emitter.emit('homeGamePaymentModalClosed');
            }

            this.$emit('close');

            this.resetState();
        },
        async nextStep() {
            if (inCapacitorBuildEnv()) {
                await this.handleInAppPurchase();
            } else {
                this.step = 2; // Continue with the web flow
            }
        },
        async handleInAppPurchase() {
            return revenueCatSubscriptionPurchase(
                this.user,
                this.selectedProduct,
            );
        },
        prevStep() {
            this.step = 1;
        },
        selectedPlanChange(product) {
            this.selectedProduct = product;
        },
        async subscribedHandler() {
            localStorage.removeItem('parentSignupFacebook');

            if (
                this.user?.role === 'parent' &&
                this.user?.flags?.parentSignupFacebook
            ) {
                this.sendPixelEvent('Parent facebook signup');

                try {
                    await SecureApi().post('/referrals/tag-email', {
                        email: this.user.email,
                        tag: 'subscribed_parent_signup_facebook_campaign',
                    });
                } catch (e) {
                    console.error(e);
                }
            }

            this.justSubscribed = true;
        },
        async fetchUserForParent() {
            const res = await SecureApi().get(
                `user/public/info/${this.$route.params.userId}`,
            );

            this.userName = `${res.data.data.firstName} ${res.data.data.lastName
                .charAt()
                .toUpperCase()}`;

            this.priceTestVersion = res.data.data.priceTestVersion;
        },
        handleParentalGatePassed() {
            // Handle the event when the parental gate is passed
            this.parentalGatePending = false;
            // Resume the payment process or take necessary actions
        },
    },
};
</script>
<style lang="scss" scoped>
.payment-modal-overlay {
    padding: var(--safe-area-inset-top) var(--safe-area-inset-right)
        var(--safe-area-inset-bottom) var(--safe-area-inset-left);
    position: fixed;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    height: 100vh;
    z-index: 100001;
    background: #572598;

    @media screen and (max-width: $w-max-tablet) {
        padding: var(--safe-area-inset-top) var(--safe-area-inset-right)
            var(--safe-area-inset-bottom) var(--safe-area-inset-left);
    }

    @media screen and (max-width: $w-max-tablet) and (orientation: landscape) {
        padding: var(--safe-area-inset-top)
            max(0px, calc(var(--safe-area-inset-right) - 6px))
            var(--safe-area-inset-bottom)
            max(0px, calc(var(--safe-area-inset-left) - 10px));
    }

    @media screen and (max-height: $h-max-under-chromebook) and (orientation: landscape) {
        width: 100%;
        height: 100%;
        overflow: auto;
    }

    @media screen and (max-width: $w-max-tablet) {
        width: 100%;
        height: 100%;
        overflow: auto;
    }

    &:before {
        content: '';
        display: block;
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100vh;
        background: url('/images/math-runner/white-pattern-opacity01.svg')
            repeat;
        pointer-events: none;
    }
}
.payment-modal-header {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1286px;
    width: 100%;
    padding: 24px 40px 16px;

    @media screen and (max-height: $h-max-under-chromebook) and (orientation: landscape) {
        max-width: 90%;
        padding: 10px 20px 0;
    }

    @media screen and (max-width: $w-max-tablet) {
        max-width: 90%;
        padding: 18px 20px 0;
    }
}
.back-button,
.payment-modal-logo {
    cursor: pointer;
}
.payment-modal-logo {
    width: 65px;
}
.payment-modal-center-header {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);

    @media screen and (max-height: $h-max-under-chromebook) and (orientation: landscape) {
        max-width: 350px;
    }

    @media screen and (max-width: $w-max-tablet) {
        max-width: 50%;
        max-height: 75px;
    }
}
</style>
