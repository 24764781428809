<template>
    <div class="privacy">
        <a :href="$getTermsOfUseLink" target="_blank" rel="noopener">
            {{ $t('auth.termsAndConditions') }}
        </a>

        {{ $t('general.and') }}

        <a :href="$getPrivacyPolicyLink" target="_blank" rel="noopener">
            {{ $t('auth.privacyPolicy') }}
        </a>
    </div>
</template>

<style lang="scss">
.privacy {
    align-self: center;
    bottom: var(--safe-area-inset-bottom);

    a {
        color: white;
        text-decoration: underline;
    }
}
</style>
