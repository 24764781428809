export default {
    addPlayer({ gameState }, payload) {
        if (
            payload &&
            Object.prototype.hasOwnProperty.call(payload, 'name') &&
            !gameState.players.some((player) => player.name === payload.name)
        ) {
            gameState.players.push(payload);
        }

        if (payload instanceof Array) {
            gameState.players = [...payload, ...gameState.players];
        }
    },
    addPlayerPublic({ gameState }, payload) {
        if (
            payload &&
            Object.prototype.hasOwnProperty.call(payload, 'name') &&
            !gameState.players.some((player) => player.name === payload.name)
        ) {
            gameState.players.push(payload);

            if (gameState.players.length > 10) {
                gameState.players.shift();
            }

            gameState.numberOfPlayers = gameState.numberOfPlayers + 1;
        }

        if (payload instanceof Array) {
            gameState.players = payload;
        }
    },
    setJoinGameMetaData({ gameState }, payload) {
        gameState.joinGameMetaData = payload;
    },
    setRequiresLogin({ gameState }, payload) {
        gameState.requiresLogin = payload;
    },
    setNumberOfPlayers({ gameState }, payload) {
        gameState.numberOfPlayers = payload;
    },
    setLeaderboard(state, payload) {
        state.leaderboard = payload;
    },
    setGameTypes({ createGame }, payload) {
        createGame.gameTypes = payload;
    },
    setTopicPickerTree({ createGame }, payload) {
        createGame.topicPickerTree = payload;
    },
    setSelfPacedPlaylist({ gameState }, payload) {
        gameState.selfPacedPlaylist = payload;
    },
    setGamePresetName({ createGame }, payload) {
        createGame.selectedGamePresetName = payload;
    },
    setGameOriginInUI({ createGame }, payload) {
        createGame.gameOriginInUI = payload;
    },
    setGameOriginInUIDetail({ createGame }, payload) {
        createGame.gameOriginInUIDetail = payload;
    },
    selectGameType({ createGame }, payload) {
        createGame.selectedGameType = payload;
    },
    selectNumberGenerator({ createGame }, payload) {
        createGame.selectedNumberGenerator = payload;
    },
    selectGameOptions({ createGame }, payload) {
        createGame.options = payload;
    },
    setGameAsPlayedAgain({ createGame }, payload) {
        createGame.playedAgain = payload;
    },
    setExampleQuestions({ createGame }, payload) {
        createGame.exampleQuestions = payload;
    },
    removePlayer({ gameState }, playerName) {
        gameState.players = gameState.players.filter(
            (player) => player.name !== playerName,
        );
    },
    removePlayerPostGame(state, playerName) {
        state.leaderboard = state.leaderboard.filter(
            (player) => player.name !== playerName,
        );
    },
    setReports(state, payload) {
        state.reports = payload;
    },
    setReportsFromSkills(state, payload) {
        state.reportsFromSkills = payload;
    },
    setReportCategories(state, payload = {}) {
        state.reportCategories = { ...payload };
    },
    setLastActive(state, payload) {
        state.lastActive = payload;
    },
    removeReport(state, payload) {
        state.reports = state.reports.filter(
            (report) => report.code !== payload,
        );
    },
    setCurriculumSet({ createGame }, payload) {
        createGame.curriculumSet = payload;
    },
    setIsOnboardingDemo(_store, payload) {
        _store.isOnboardingDemo = payload;
    },
    setLiveGameOptions({ createGame }, payload) {
        createGame.liveGameOptions = payload;
    },
    setLiveLeaderboardSeeStudents({ user }, payload) {
        user.liveLeaderboardSeeStudents = payload;
    },
    setCountry(state, payload) {
        state.country = payload;
    },
};
