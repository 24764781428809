<template>
    <ParentInfoModal @close="closeModal">
        <div class="content">
            <div class="left">
                <div class="text-block">
                    <div class="title text-header">
                        MAKE MATH
                        <span class="green">FUN AND EFFECTIVE</span>
                        FOR YOUR CHILD
                    </div>
                    <div class="paragraph">
                        <div>
                            99math is a game-changing way to help your child
                            math with enthusiasm.
                        </div>
                        <div>
                            Loved in the classrooms and ideal for home, 99math
                            transforms math practice into an exciting adventure.
                        </div>
                    </div>
                </div>
                <div class="subscribe-now">
                    <SubscribeNow @closeModal="closeModal" />
                </div>
                <div class="black-text-block">
                    <div class="black-text-with-image">
                        <img src="/ui/game/128/energy-bunny-dino.svg" alt="" />
                        <BlackTitleText
                            :title="'ENGAGING GAMEPLAY'"
                            :text="'Our interactive game keeps your child motivated and interested.'"
                        />
                    </div>
                    <div class="black-text-with-image">
                        <img src="/ui/game/128/fluency-with-stars.svg" alt="" />
                        <BlackTitleText
                            :title="'IMPROVED MATH FLUENCY'"
                            :text="'Witness your child’s growing confidence and competence in math.'"
                        />
                    </div>
                    <div class="black-text-with-image">
                        <img src="/ui/game/128/school-home.svg" alt="" />
                        <BlackTitleText
                            :title="'SEAMLESS FROM SCHOOL TO HOME'"
                            :text="'99math creates a continuous learning experience with personalized assignments.'"
                        />
                    </div>
                </div>
            </div>
            <div class="right">
                <div class="research-hero">
                    <div class="images">
                        <img
                            src="/ui/game/big-assets/kid-home.png"
                            alt="kid-home"
                        />
                        <img
                            src="/ui/game/big-assets/kids-school.png"
                            alt="kids-school"
                        />
                    </div>
                    <div
                        class="text text-body-l black boxed research-hero-text"
                    >
                        WHAT RESEARCH SAYS ABOUT THE IMPORTANCE OF EARLY MATH
                        EDUCATION:
                    </div>
                </div>
                <div class="accordions">
                    <OldAccordion :data="accordionData" />
                </div>
            </div>
        </div>
    </ParentInfoModal>
</template>
<script>
import ParentInfoModal from './components/ParentInfoModal.vue';
import BlackTitleText from './components/BlackTitleText.vue';
import SubscribeNow from './components/SubscribeNow.vue';
import OldAccordion from './components/Accordion.vue';
export default {
    components: { ParentInfoModal, BlackTitleText, SubscribeNow, OldAccordion },
    emits: ['close'],
    data() {
        return {
            accordionData: [
                {
                    title: `MATH SKILLS ARE A PREDICTOR OF <span class="green">FUTURE ACADEMIC SUCCESS</span>`,
                    description: `Did you know how well kids understand math when they start school can predict how good they will be at not just math, but also reading later on?`,
                },
                {
                    title: `FUN MATH <span class="green">REDUCED</span> MATH ANXIETY`,
                    description: `Research discussed by Lisa Ginet, EdD, from the Early Math Collaborative, has found that if kids have fin and stress-free experiences with math when they are young, they are less likely to be scared of math later on.`,
                },
                {
                    title: `PARENTS PLAY A <span class="green">BIG ROLE</span> IN MATH LEARNING`,
                    description: `Your home and the way you talk about math with your child can make a huge difference in how they learn math. Research shows that when parents are involved and make math a part of daily life, kids learn better.`,
                },
            ],
        };
    },
    methods: {
        closeModal() {
            this.$emit('close');
        },
    },
};
</script>
<style lang="scss" scoped>
.content {
    display: flex;
    justify-content: space-evenly;
    padding: 3rem;
    gap: 1rem;
}
.left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 2rem;
    width: 55%;
}
.right {
    width: 45%;
}
.paragraph {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}
.text-block {
    display: flex;
    flex-direction: column;
    gap: 3rem;
}
.title {
    text-align: left;
    @include mq($w-max-tablet, max) {
        font-size: 40px;
    }
}
.paragraph {
    color: var(--Neutral-100, #fff);
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    display: flex;
    gap: 1rem;
    flex-direction: column;
}
.green {
    color: var(--Green-300, #10ff8f);
}
.black-text-block {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}
.black-text-with-image {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
    justify-content: center;
}
.research-hero {
    position: relative;
}
.images {
    display: flex;
    gap: 1rem;
    overflow: hidden;
}
.research-hero-text {
    line-height: 32px;
    text-align: left !important;
    width: fit-content;
    position: relative;
    top: -50px;
    max-width: 426px;

    @include mq($w-max-tablet, max) {
        top: -70px;
        font-size: 24px;
        line-height: 24px;
    }
}
.accordions :deep(.green) {
    color: var(--Green-300, #10ff8f);
}
</style>
