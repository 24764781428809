import App from '@/App.vue';
import { HealthRoutes } from '@/core/pages/HealthCheckToBE/HealthRoutes';
import { HomeGameRoutes } from '@/student/HomeGameRoutes.js';
import { MathRunnerRoutes } from '@/flows/MathRunner/routes';
import { TeacherRoutes } from '@/teacher/routes.js';

import Math99Mixin from '@/core/mixins/Math99Mixin';
import ThemeTeacher from '@/teacher/ThemeTeacher.js';
import * as SentryVue from '@sentry/vue';
import * as SentryCapacitor from '@sentry/capacitor';

import 'animate.css';
import FloatingVue from 'floating-vue';
import 'floating-vue/dist/style.css';
import mitt from 'mitt';

import PrimeVue from 'primevue/config';
import ToastService from 'primevue/toastservice';
import Tooltip from 'primevue/tooltip';

import io from 'socket.io-client';
import VAnimateCss from 'v-animate-css';
import vClickOutside from 'v-click-outside';
import { createApp, h } from 'vue';
import { createRouter, createWebHistory } from 'vue-router';
import VueScrollactive from 'vue-scrollactive';
import vSelect from 'vue-select';
import VueSocketIO from 'vue-socket.io-extended';
// VueToggle import: https://github.com/juliandreas/vue-toggles/issues/3#issuecomment-841624793
import VueToggles from 'vue-toggles/src/VueToggles.vue';
import Vue3TouchEvents from 'vue3-touch-events';
import 'floating-vue/dist/style.css';
import '@/student/ui/swatches.css';

import 'animate.css';

import {
    homePageRoute,
    requireGameOwner,
    requireTeacherRole,
} from '@/core/helpers/RouteHelpers';

import { i18n } from './lang/translator';
import { routes } from './routes';
import store from './store';
import { inCapacitorBuildEnv } from '@/core/helpers/utils';
import { initCapacitor } from '@/capacitor/init';
import { MiniGamesRoutes } from '@/mini-games/mini-games.routes.js';

FloatingVue.options.themes.menu.delay.hide = 0;

const RootComponent = {
    async mounted() {
        if (inCapacitorBuildEnv()) {
            await initCapacitor();
        }
    },
    render() {
        return h(App);
    },
};
export const app = createApp(RootComponent);

const initUserToken = localStorage.getItem('authToken');
const initGuestToken = localStorage.getItem('guestToken');
const initJwt = initUserToken || initGuestToken;
// "classic" io manager for v1 connections
const ioManager = io(import.meta.env.VITE_API_URL, {
    autoConnect: false,
    transports: ['websocket'],
    cors: [import.meta.env.VITE_API_URL],
    // sending JWT in query, cuz
    // "In a browser environment, the extraHeaders option will be ignored if you only enable the WebSocket transport, since the WebSocket API in the browser does not allow providing custom headers." (https://socket.io/docs/v4/client-options/#extraheaders)
    // but token should be accessible on allowRequest
    query: {
        jwt: initJwt,
    },
    multiplex: false,
    forceNew: true,
});
let ioCC = 0;
ioManager.on('reconnect_attempt', () => {
    const userToken = localStorage.getItem('authToken');
    const guestToken = localStorage.getItem('guestToken');
    const jwt = userToken || guestToken;
    ioCC++;
    ioManager.io.opts.transports = ['websocket'];
    ioManager.io.opts.query.CC = ioCC;
    ioManager.io.opts.query.jwt = jwt;
});

export const router = createRouter({
    history: createWebHistory(),
    routes: [
        ...HealthRoutes,
        ...HomeGameRoutes,
        ...MathRunnerRoutes,
        ...TeacherRoutes,
        ...MiniGamesRoutes,
        // unsafe /:ID in routes.js, don't put /oneStep URLs below routes
        ...routes,
    ],
});
// Run only in prod.
const { VITE_ENVIRONMENT, VITE_APP_VERSION, VITE_API_URL } = import.meta.env;
if (VITE_ENVIRONMENT && VITE_ENVIRONMENT !== 'development') {
    SentryCapacitor.init(
        {
            Vue: app,
            dsn: 'https://da0f44c003214547a739930197d0c46a@sentry.io/1769995',
            release: `${VITE_APP_VERSION}`,
            environment: VITE_ENVIRONMENT,
            integrations: [SentryVue.browserTracingIntegration()],
            tracesSampleRate: VITE_ENVIRONMENT === 'production' ? 1 : 0.2,
            tracePropagationTargets: [
                'localhost',
                'capacitor',
                new URL(VITE_API_URL)?.hostname,
            ],
            debug: false, // VITE_ENVIRONMENT !== 'production',
            tracingOptions: {
                trackComponents: true,
            },
            // Vue specific
            logErrors: VITE_ENVIRONMENT === 'production',
            trackComponents: true,
            attachProps: true,
            attachStacktrace: true,
        },
        SentryVue.init,
    );
}

router.beforeEach(async (to, _from, next) => {
    // should be called only once, on any 1st route
    if (!store.state.v2.ready) await store.dispatch('v2/init');
    if (to.name === 'home' && inCapacitorBuildEnv()) {
        return next({ name: 'auth.login' });
    }

    // special cases
    if (to.name !== 'remote-learning-estonia' && to.query['ref_id']) {
        // redirects to Referral
        return next({ path: '/ref_id' + '/?upviral_id=' + to.query['ref_id'] });
    }

    // page accessible for all
    if (
        !to.matched.some(
            (route) => typeof route.meta.authenticate !== 'undefined',
        )
    )
        return next();

    const isAuthenticated = await store.dispatch('checkJwt');
    const homePage = homePageRoute();

    // home page has special rules
    if (to.name === 'home') {
        if (homePage.name === 'home') return next();
        return next(homePage);
    }
    if (to.name === 'student-demo') {
        return next();
    }
    if (to.name === 'buy-premium') {
        return next();
    }
    // special "guest only" pages
    if (to.matched.some((route) => route.meta.authenticate === false)) {
        // Routes for user that isn't authenticated.
        // Redirect logged-in user to homepage.
        return isAuthenticated ? next(homePage) : next();
    }
    // other possible guards should require authenticate meta flag also
    if (to.matched.some((route) => route.meta.authenticate === true)) {
        // Routes for authenticated user.
        // Redirect not authenticated user to login page.
        if (!isAuthenticated) {
            await store.dispatch('setNextPath', to);
            return next({ name: 'auth.login', query: to.query });
        }
    }
    // game creators (owners) pages
    if (to.matched.some((route) => route.meta.isGameOwner === true)) {
        // Routes for gameOwners user.
        // Not passed redirected to home
        const isOwner = await requireGameOwner(to.params.gameCode);
        if (!isOwner) return next(homePage);

        // report pages
        if (to.matched.some((route) => route.meta.requireGameReport === true)) {
            if (!store.getters['v2/teacher/report'](to.params.gameCode))
                await store.dispatch('v2/teacher/loadReports', { page: 1 });
        }
    }
    // teachers only pages
    if (to.matched.some((route) => route.meta.requireTeacherRole === true)) {
        // Redirect not authenticated user to home page, and non teachers to /play page
        const redirectPath = requireTeacherRole();
        if (redirectPath) return next(redirectPath);
    }
    // Default action
    next();
});

function persistRouteHistoryToStorage(to) {
    const routerHistory = localStorage.getItem('routerHistory');
    if (routerHistory) {
        let rH = JSON.parse(routerHistory);
        if (rH.length > 100) {
            rH.splice(0, 50);
        }
        rH.push({ fp: to.fullPath, name: to.name });
        localStorage.setItem('routerHistory', JSON.stringify(rH));
    } else {
        localStorage.setItem(
            'routerHistory',
            JSON.stringify([{ fp: to.fullPath, name: to.name }]),
        );
    }
}

router.afterEach((to) => {
    persistRouteHistoryToStorage(to);

    // turn on dark theme for the teacher UI
    const elHtml = document.querySelector('html');
    if (to.path?.includes('teacher24')) {
        elHtml.classList.add('theme-dark');
    } else {
        elHtml.classList.remove('theme-dark');
    }

    window.gtag('config', window.GA_TRACKING_ID, {
        page_path: to.fullPath,
        send_page_view: true,
    });
});

const maxImportFail = 3;
router.onError((error, to) => {
    const importFailString = sessionStorage.getItem('moduleImportFail');
    let importFail = parseInt(importFailString) || 0;
    if (importFail > maxImportFail) return;
    if (
        error?.message?.includes(
            'Failed to fetch dynamically imported module',
        ) ||
        error?.message?.includes('Importing a module script failed')
    ) {
        importFail++;
        sessionStorage.setItem('moduleImportFail', importFail);
        console.log(`Detected error: ${error.message}`);
        window.location = to.fullPath;
    }
});
router.afterEach(() => {
    sessionStorage.removeItem('moduleImportFail');
});

const socketIo = io(import.meta.env.VITE_API_URL, {
    autoConnect: false,
    transports: ['websocket'],
});

socketIo.on('reconnect_attempt', () => {
    socketIo.io.opts.transports = ['websocket'];
});

app.use(store);
app.use(VAnimateCss);
app.use(VueSocketIO, ioManager);
app.use(router);
app.use(vClickOutside);
app.use(VueScrollactive);
app.use(Vue3TouchEvents);
app.use(FloatingVue, {
    themes: {
        'mistakes-tooltip': {
            $extend: 'tooltip',
        },
        'see-report-tooltip': {
            $extend: 'tooltip',
        },
    },
});
app.use(PrimeVue, {
    theme: {
        preset: ThemeTeacher,
        options: {
            cssLayer: true,
            darkModeSelector: '.theme-dark',
        },
    },
});
app.use(ToastService);
app.directive('p-tooltip', Tooltip);

app.mixin(Math99Mixin);

app.component('VueToggles', VueToggles);
app.component('VSelect', vSelect);

const { t } = i18n;
app.config.globalProperties.$t = t;
app.config.globalProperties.$i18n = i18n;
const emitter = mitt();
app.config.globalProperties.$emitter = emitter;
app.provide('$emitter', emitter);
app.config.globalProperties.$store = store;

app.config.globalProperties.$emitter.on('guestJwtAcquired', () => {
    const userToken = localStorage.getItem('authToken');
    const guestToken = localStorage.getItem('guestToken');
    ioManager.io.opts.query.jwt = userToken || guestToken;
});

app.config.productionTip = false;

const vm = app.mount('#app');

export default vm;
