import Api from '@/core/services/Api';
import { app } from '@/main';
import getBackgrounds from '@/core/static-json/avatar/BackgroundsList.json';
import getCustomAvatars from '@/core/static-json/avatar/customAvatar2d.json';
import getFramesList from '@/core/static-json/avatar/FramesList.json';
import moment from 'moment';
import { randomArrayElement, randomIntFromRange } from '@/core/helpers/utils';
import { getRandomBotName } from '@/core/helpers/bots/botsUtils';
import { OneVOneBot } from '@/core/helpers/bots/OneVOneBot';
import TopicsFactory from '@/core/math-topics/TopicsFactory';
import { jclone, shuffleArray } from '@/core/helpers/utils';
import { Hotjar } from '@/core/services/Hotjar';

const store = {
    namespaced: true,
    state: {
        opponentBot: null,
        matchMade: false,
        playerReactions: {},
        roundStarting: false,
        roundEnd: false,
        gameEnded: false,
        boosters: {},
        preRoundTimeElapsed: 0,
        pickedIfBoosters: null,
        boosterChoices: [
            {
                id: 'blackhole',
                text: 'Delete a Key',
                secondsLasting: 10,
                uses: 1,
                target: 'opponent',
                amount: 12,
                imageSrc: '/images/onevone/boosters/blackhole.png',
            },
            {
                id: 'tornado',
                text: 'Keyboard Tornado',
                secondsLasting: 10,
                uses: 1,
                target: 'opponent',
                amount: 12,
                imageSrc: '/images/onevone/boosters/tornado.png',
            },
            {
                id: 'shield',
                text: 'Safety Shield',
                secondsLasting: 10,
                uses: 1,
                target: 'self',
                amount: 4,
                imageSrc: '/images/onevone/boosters/shield.png',
            },
            {
                id: 'skip',
                text: 'Skip 3x Questions',
                secondsLasting: null,
                uses: 3,
                target: 'self',
                amount: 8,
                imageSrc: '/images/onevone/boosters/skip.png',
            },
        ],
    },
    mutations: {
        resetStateToDefault: (state) => {
            state.opponentBot?.stopBotGamePlay();
            state.opponentBot = null;
            state.matchMade = false;
            state.playerReactions = {};
            state.roundStarting = false;
            state.roundEnd = false;
            state.gameEnded = false;
            state.boosters = {};
            state.pickedIfBoosters = null;
        },
        setOpponent: (state, next) => {
            state.opponentBot = next;
        },
        setMatchMade: (state, next) => {
            state.matchMade = next;
        },
        setRoundStarting: (state, next) => {
            state.roundStarting = next;
        },
        setGameEnded: (state, next) => {
            state.gameEnded = next;
        },
        setRoundEnd: (state, next) => {
            state.roundEnd = next;
        },
        setPlayerReaction: (state, next) => {
            state.playerReactions[next.playerName] = next.reaction;
        },
        resetPlayerReactions: (state) => {
            state.playerReactions = {};
        },
        toggleBooster: (state, next) => {
            if (!state.boosters[next.playerName]) {
                state.boosters[next.playerName] = [];
            }
            const myBoosters = state.boosters[next.playerName];
            if (
                myBoosters
                    .map((booster) => booster.id)
                    .includes(next.booster.id)
            ) {
                state.boosters[next.playerName] = state.boosters[
                    next.playerName
                ].filter((_b) => _b.id !== next.booster.id);
            } else if (myBoosters.length < 2) {
                state.boosters[next.playerName].push(next.booster);
            }
        },
        startBooster: (state, next) => {
            if (!state.boosters[next.playerName]) {
                state.boosters[next.playerName] = [];
            }
            state.boosters[next.playerName] = state.boosters[
                next.playerName
            ].map((b) => (b.id === next.booster.id ? next.booster : b));
        },
        setBoosters: (state, next) => {
            state.boosters = next;
        },
        pickIfBoosters: (state, next) => {
            state.pickedIfBoosters = next;
        },
        preRoundTimeElapsed: (state, next) => {
            state.preRoundTimeElapsed = next;
        },
    },
    getters: {
        opponentStats: (state, getters) => {
            return getters.getOpponent?._stats;
        },
        getOpponent: (state) => {
            return state.opponentBot;
        },
        opponentPlayerName: (state) => {
            return state.opponentBot?._playerLook?.playerName;
        },
        winState: (state, getters, rootState, rootGetters) => {
            if (
                rootGetters['v2/homegame/stats'].correctAnswers ===
                getters.opponentStats.correctAnswers
            ) {
                return 'draw';
            }
            return rootGetters['v2/homegame/stats'].correctAnswers >
                getters.opponentStats.correctAnswers
                ? 'won'
                : 'lost';
        },
        allBoosters: (state) => {
            return state.boosters || {};
        },
        myBoosters: (state, getters, rootState, rootGetters) => {
            return state.boosters[rootGetters['v2/user/playerName']] || [];
        },
        opponentBoosters: (state, getters) => {
            return state.boosters[getters.opponentPlayerName] || [];
        },
        affectSelf: (state, getters) => {
            const fromMe = getters.myBoosters.filter(
                (booster) => booster.target === 'self' && booster.active,
            );
            const fromOpponent = getters.opponentBoosters.filter(
                (booster) => booster.target === 'opponent' && booster.active,
            );
            return [...fromMe, ...fromOpponent];
        },
        affectOpponent: (state, getters) => {
            const fromMe = getters.opponentBoosters.filter(
                (booster) => booster.target === 'self' && booster.active,
            );
            const fromOpponent = getters.myBoosters.filter(
                (booster) => booster.target === 'opponent' && booster.active,
            );
            return [...fromMe, ...fromOpponent];
        },
    },
    actions: {
        saveCache: (store) => {
            const cache = {
                matchMade: store.state.matchMade,
                roundStarting: store.state.roundStarting,
                roundEnd: store.state.roundEnd,
                gameEnded: store.state.gameEnded,
                boosters: store.state.boosters,
                pickedIfBoosters: store.state.pickedIfBoosters,
                opponentBot: store.state.opponentBot
                    ? {
                          _playerLook: store.state.opponentBot._playerLook,
                          _stats: store.state.opponentBot._stats,
                          _tpm: store.state.opponentBot._tpm,
                      }
                    : null,
            };

            sessionStorage.setItem('cache/one-v-one', JSON.stringify(cache));
        },
        loadCache: async (store) => {
            const jcache = sessionStorage.getItem('cache/one-v-one');

            if (!jcache) return;

            let cache;

            try {
                cache = JSON.parse(jcache);
            } catch (err) {
                cache = null;

                console.error('store::onevone loading cache error', err);
            }

            if (!cache) return;

            const botObject = new OneVOneBot(
                cache.opponentBot._playerLook,
                cache.opponentBot._tpm,
                cache.opponentBot._stats,
            );
            if (!cache.roundEnd && cache.gameEnded) {
                await botObject.resumeBot();
            }
            store.commit('setOpponent', botObject);
            store.commit('setRoundEnd', cache.roundEnd);
            store.commit('setGameEnded', cache.gameEnded);
            store.commit('setRoundStarting', cache.roundStarting);
            store.commit('setMatchMade', cache.matchMade);
            store.commit('setBoosters', cache.boosters);
            store.commit('pickIfBoosters', cache.pickedIfBoosters);
        },
        clearCache: () => {
            sessionStorage.removeItem('cache/one-v-one');
        },
        resetState: (store) => {
            store.dispatch('clearCache');
        },
        resetStateToDefault: (store) => {
            store.commit('resetStateToDefault');
        },
        getTpmForSkill: async (
            store,
            { skill, gameType, numberGenerator, grade, nodeLevel },
        ) => {
            let skillData;
            const localTpm = localStorage.getItem(`${nodeLevel}-tpm`);
            if (localTpm) {
                const currentTpm = parseInt(localTpm);
                const bias = randomArrayElement([-1, -2, -3]);
                let tpm = currentTpm + bias;
                tpm = tpm < 1 ? 1 : tpm;
                localStorage.setItem(`${nodeLevel}-tpm`, tpm);
                return tpm;
            }
            try {
                const response = await Api().post(
                    'home-game-v10SimpleTreeSocial/player',
                    {
                        gameType,
                        numberGenerator,
                    },
                );

                if (response.data.success) {
                    skillData = response.data.data || null;
                }
            } catch (e) {
                console.error(e);
            }
            let tpm = skillData?.[0]?.fluency;
            console.debug(
                `[1v1] bot tpm from home-game-v10SimpleTreeSocial/player: ${tpm}`,
            );
            if (!tpm) {
                try {
                    const response = await Api().get(
                        `solo-track/student/skill/fluency?topic=${skill.topic}&skillName=${skill.name}`,
                    );
                    if (response.data.success) {
                        skillData = response.data.data || null;
                    }
                } catch (e) {
                    console.error(e);
                }
                tpm = skillData?.avgFluency
                    ? Math.round(skillData?.avgFluency)
                    : null;
            }
            console.debug(
                `[1v1] bot tpm from solo-track/student/skill/fluency: ${tpm}`,
            );
            if (!tpm) {
                if (['1', '2'].includes(grade)) {
                    tpm = 8;
                } else {
                    tpm = 10;
                }
            }
            tpm = tpm < 1 ? 1 : tpm;
            localStorage.setItem(`${nodeLevel}-tpm`, tpm);
            return tpm;
        },
        createRandomBot: async (store, { raritiesToInclude, currentTpm }) => {
            let customAvatar = {};
            const complexTypes = {
                ['hair-front']: 'hair',
                ['full-face']: 'fullFace',
            };
            for (let group of getCustomAvatars) {
                const rawPool = group.items.slice(1); // remove empty item
                let pool = !raritiesToInclude
                    ? rawPool
                    : rawPool.filter((item) =>
                          raritiesToInclude.includes(item.rarity),
                      );
                // filter could empty the pool out, default to whole pool
                if (!pool.length) {
                    pool = rawPool;
                }
                const randomItem = randomArrayElement(pool);
                const type = complexTypes[randomItem.type] || randomItem.type;
                customAvatar[type] = randomItem.id.split('_')[1];
            }

            const frame = randomArrayElement(getFramesList)?.id;
            const background = randomArrayElement(getBackgrounds)?.id;
            const pet = randomArrayElement(store.getters.getAllCards)?.name;

            const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
            const randomLetter = characters.charAt(
                Math.floor(Math.random() * characters.length),
            );
            const playerName = `${getRandomBotName()}${randomLetter}${randomIntFromRange(11, 99)}`;
            const bias = randomArrayElement([-1, -2, -3]);
            let tpm = currentTpm + bias;
            console.debug(
                `[1v1] current bot tpm is: ${currentTpm} + ${bias} = ${tpm}`,
            );
            const botObject = new OneVOneBot(
                {
                    customAvatar,
                    playerName,
                    avatar: 'custom',
                    frame,
                    background,
                    pet,
                },
                tpm,
            );

            store.commit('setOpponent', botObject);
        },
        react: (store, { reaction, playerName }) => {
            store.commit('setPlayerReaction', { reaction, playerName });
        },
        prepareGame: async (
            store,
            { isPlayAgain, skill, topic, nodeLevel },
        ) => {
            Hotjar.tagRecording(['Starts 1v1 game']);
            if (!isPlayAgain) {
                const tpm = await store.dispatch('getTpmForSkill', {
                    skill: {
                        ...skill,
                        topic: topic?.name || skill.name,
                    },
                    gameType: skill.type,
                    numberGenerator: skill.numberGenerator,
                    grade: store.rootGetters['v2/user/user']?.studentInfo
                        ?.gradeInfo?.grade,
                    nodeLevel,
                });
                await store.dispatch('createRandomBot', {
                    raritiesToInclude: ['epic', 'legendary'],
                    currentTpm: tpm,
                });
            }
            const math = skill.skillChoices.map((_skill) =>
                TopicsFactory.getTopicObject(jclone(_skill)),
            );
            const gameQuestions = math
                .map((generator, index) => {
                    return generator.generateQuestions(35).map((q) => {
                        q.mathObjectIndex = index;
                        return q;
                    });
                })
                .flat();
            const questions = shuffleArray(gameQuestions);
            return { questions, math };
        },
        activateBooster: (store, { booster, playerName }) => {
            if (booster.active) return;
            if (booster.secondsLasting) {
                booster.active = moment().format();
                booster.uses -= 1;
            } else {
                if (booster.active) return;
                booster.active = true;
                setTimeout(() => {
                    if (booster.uses === 0) {
                        store.dispatch('deactivateBooster', {
                            booster,
                            playerName,
                        });
                        return;
                    }
                    booster.active = false;
                }, 500);
                booster.uses -= 1;
            }
            const myPlayerName = store.rootGetters['v2/user/playerName'];
            const opponentPlayerName = store.getters.opponentPlayerName;
            switch (booster.id) {
                case 'skip':
                    if (playerName === myPlayerName) {
                        app.config.globalProperties.$emitter.emit(
                            'answerForPlayer',
                        );
                    } else {
                        store.state.opponentBot._stats.correctAnswers += 1;
                    }
                    break;
                case 'shield':
                    if (playerName === myPlayerName) {
                        store.dispatch('deactivateBooster', {
                            booster: { id: 'tornado' },
                            playerName: opponentPlayerName,
                        });
                        store.dispatch('deactivateBooster', {
                            booster: { id: 'blackhole' },
                            playerName: opponentPlayerName,
                        });
                    } else {
                        store.dispatch('deactivateBooster', {
                            booster: { id: 'tornado' },
                            playerName: myPlayerName,
                        });
                        store.dispatch('deactivateBooster', {
                            booster: { id: 'blackhole' },
                            playerName: myPlayerName,
                        });
                    }
                    break;
                case 'tornado':
                case 'blackhole':
                    if (playerName === myPlayerName) {
                        const hasShield = store.getters.affectOpponent.find(
                            (b) => b.id === 'shield',
                        );
                        if (hasShield) return;
                    } else {
                        const hasShield = store.getters.affectSelf.find(
                            (b) => b.id === 'shield',
                        );
                        if (hasShield) return;
                    }
                    break;
            }
            app.config.globalProperties.$emitter.emit('booster-activated', {
                booster,
                playerName,
            });
            store.commit('startBooster', { booster, playerName });
        },
        deactivateBooster: (store, { booster, playerName }) => {
            if (!booster.active) return;
            store.commit('toggleBooster', { booster, playerName });
        },
        tickBoosters: (store) => {
            const allBoosters = store.getters.allBoosters;
            for (const [playerName, boosters] of Object.entries(allBoosters)) {
                for (const booster of boosters) {
                    if (!booster.secondsLasting) return;
                    if (!booster.active) return;
                    const currentTime = moment();
                    const secondsPassed = currentTime.diff(
                        moment(booster.active),
                        'seconds',
                    );
                    if (secondsPassed >= booster.secondsLasting) {
                        booster.percentage = 100;
                        store.dispatch('deactivateBooster', {
                            booster,
                            playerName: playerName,
                        });
                        return;
                    }
                    booster.percentage = Math.round(
                        (secondsPassed / booster.secondsLasting) * 100,
                    );
                }
            }
        },
    },
};

export default store;
