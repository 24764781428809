<template>
    <div class="subscribe-now-wrapper">
        <img
            src="/images/bp-coupon/math-pass.svg"
            alt="math-pass"
            class="subscribe-now-image"
        />
        <button class="subscribe-now" @click="closeModal">Subscribe now</button>
    </div>
</template>
<script>
export default {
    emits: ['closeModal'],
    methods: {
        closeModal() {
            this.$emit('closeModal');
        },
    },
};
</script>
<style lang="scss" scoped>
.subscribe-now-wrapper {
    position: relative;
}
.subscribe-now {
    border-radius: 32.806px;
    background: #2be480;
    box-shadow: 0 2.624px 6.561px 0 rgba(87, 37, 152, 0.3);
    color: var(--Main-Body-type, #333);
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32.806px;
    width: 297px;
    height: 62px;
    flex-shrink: 0;
    border: none;
    text-align: right;
    padding-right: 1.5rem;

    &:hover {
        background: darken(#2be480, 10%);
    }
}
.subscribe-now-image {
    position: absolute;
    left: -40px;
    top: -15px;
    width: 120px;
}
</style>
