<template>
    <div class="black-text-title">
        <div class="text text-body-m boxed title">{{ title }}</div>
        <div class="text">{{ text }}</div>
    </div>
</template>
<script>
export default {
    props: {
        title: {
            type: String,
        },
        text: {
            type: String,
        },
    },
};
</script>
<style lang="scss" scoped>
.black-text-title {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.title {
    text-align: left;
    width: fit-content;
}
.text {
    color: var(--Neutral-100, #fff);
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    text-align: left;
}
</style>
