<template>
    <ButtonMedium
        v-if="!hasNoParentInfo && (hasParentInfo1 || hasParentInfo2)"
        :text="'Parent info'"
        class="button-layout"
        @click="handleOpenParentInfo"
    />
    <template v-if="openParentInfo">
        <ParentInfo1 v-if="hasParentInfo1" @close="closeModal" />
        <ParentInfo2 v-else-if="hasParentInfo2" @close="closeModal" />
    </template>
</template>
<script>
import ButtonMedium from '@/student/ui/button/ButtonMedium.vue';
import ParentInfo1 from './ParentInfo1.vue';
import ParentInfo2 from './ParentInfo2.vue';
import {
    EVENTS,
    TrackingService,
} from '@/core/services/TrackingService/TrackingService';

export default {
    components: { ButtonMedium, ParentInfo1, ParentInfo2 },
    data() {
        return {
            openParentInfo: false,
        };
    },
    computed: {
        hasNoParentInfo() {
            return ['group-1', 'group-5'].includes(this.$user?.testGroup);
        },
        hasParentInfo1() {
            return ['group-2', 'group-4'].includes(this.$user?.testGroup);
        },
        hasParentInfo2() {
            return ['group-3', 'group-6'].includes(this.$user?.testGroup);
        },
    },
    methods: {
        closeModal() {
            this.openParentInfo = false;
            new TrackingService().track(EVENTS.PARENT_INFO_MODAL_CLOSED);
        },
        handleOpenParentInfo() {
            this.openParentInfo = true;
            new TrackingService().track(EVENTS.PARENT_INFO_MODAL_OPENED);
        },
    },
};
</script>
<style lang="scss" scoped>
.button-layout {
    margin: 1rem 2rem;
}
</style>
