/* global mixpanel */

export const TEACHER_UI_EVENTS = {
    STEP_GOAL_CLICKED: 'stepGoalClicked',
    STEP_ASSIGNMENTS_CLICKED: 'stepAssignmentsClicked',
    DEMO_ENTERED: 'demoEntered',
    DEMO_EXITED: 'demoExited',
    WEEKLY_GOAL_SET: 'weeklyGoalSet',
    NAV_TO_CLASSES: 'navToClasses',
    NAV_TO_REPORTS: 'navToReports',
    NAV_TO_ST: 'navToST',
    SWITCHED_TO_OLD_UI: 'switchedToOldUI',
    MENU_COLLAPSED: 'menuCollapsed',
    MENU_EXPANDED: 'menuExpanded',
    DISMISSED_WELCOME: 'dismissedWelcome',
    DISMISSED_GOAL_WHY: 'dismissedGoalWhy',
    CLICKED_WHO_IS_PLAYING: 'clickedWhoIsPlaying',
    CLICKED_WHO_IS_NOT_PLAYING: 'clickedWhoIsNotPlaying',
    COMPLETED_HOME_ACCESS: 'completedHomeAccess',
};

export const TEACHER_UI_METHODS = {
    stepGoalClicked({ stepStatus }) {
        mixpanel.track('Teacher clicked set a goal step', {
            'Step Status': stepStatus,
        });
    },
    stepAssignmentsClicked({ stepStatus }) {
        mixpanel.track('Teacher clicked assignments step', {
            'Step Status': stepStatus,
        });
    },
    demoEntered() {
        mixpanel.track('Teacher entered demo');
    },
    demoExited({ slide }) {
        mixpanel.track('Teacher exited demo', {
            Slide: slide,
        });
    },
    weeklyGoalSet({ newGoal, prevGoal, isCustom }) {
        mixpanel.track('Teacher set weekly goal', {
            'New Goal (minutes)': newGoal,
            'Previous Goal (minutes)': prevGoal,
            'Is Custom': isCustom,
        });
    },
    navToClasses({ origin }) {
        mixpanel.track('Teacher navigated to classes', {
            Origin: origin, // sidebar, class selector
        });
    },
    navToReports() {
        mixpanel.track('Teacher navigated to reports');
    },
    navToST() {
        mixpanel.track('Teacher navigated to Individual Practice');
    },
    switchedToOldUI({ way }) {
        mixpanel.track('Teacher switched to old UI', {
            'Navigation Way': way, // menu, logo
        });
    },
    menuCollapsed() {
        mixpanel.track('Teacher collapsed sidebar');
    },
    menuExpanded() {
        mixpanel.track('Teacher expanded sidebar');
    },
    dismissedWelcome() {
        mixpanel.track('Teacher dismissed welcome screen');
    },
    dismissedGoalWhy() {
        mixpanel.track('Teacher dismissed goal Why & How');
    },
    clickedWhoIsPlaying() {
        mixpanel.track('Teacher clicked Who is playing');
    },
    clickedWhoIsNotPlaying() {
        mixpanel.track('Teacher clicked Who is not playing');
    },
    completedHomeAccess() {
        mixpanel.track('Teacher completed home access step');
    },
};
