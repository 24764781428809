<template>
    <ParentInfoModal @close="closeModal">
        <div class="content">
            <div class="left">
                <div class="text-block">
                    <div class="text-display text black title">
                        <span class="green">UNLOCK EXCELLENCE</span>
                        WITH MATH PASS!
                    </div>
                    <div class="paragraph">
                        <div>
                            Empower your child with 99math, your fun math tutor
                            designed to build confidence and master math facts.
                        </div>
                        <div>
                            Start now and see the progress unfold as 99math
                            boosts confidence and mastery in math.
                        </div>
                    </div>
                </div>
                <div class="text-body-m paragraph">
                    <div>
                        SUBSCRIBE NOW AND
                        <span class="orange">TRANSFORM HOMEWORK</span>
                        INTO A FUN, PRODUCTIVE TIME.
                    </div>
                    <div>LET’S MAKE MATH A JOYOUS JOURNEY TOGETHER!</div>
                </div>
                <div class="subscribe-now">
                    <SubscribeNow @closeModal="closeModal" />
                </div>
            </div>
            <div class="right">
                <img
                    src="/ui/game/big-assets/kids-school-why.png"
                    alt="kids-school"
                    class="kids-school-why"
                />
                <div class="black-text-block">
                    <BlackTitleText
                        :title="'PERSONALIZED LEARNING'"
                        :text="'Adapts to your child’s pace, ensuring they grasp math concepts fully.'"
                    />
                    <BlackTitleText
                        :title="'CONFIDENCE BOOST'"
                        :text="'Watch as your child develops a love for math, enhancing their skills.'"
                    />
                    <BlackTitleText
                        :title="'COST-EFFECTIVE'"
                        :text="'At a fraction of the cost of traditional tutoring, get comprehensive math support.'"
                    />
                </div>
            </div>
        </div>
    </ParentInfoModal>
</template>
<script>
import ParentInfoModal from './components/ParentInfoModal.vue';
import BlackTitleText from './components/BlackTitleText.vue';
import SubscribeNow from './components/SubscribeNow.vue';

export default {
    components: { ParentInfoModal, BlackTitleText, SubscribeNow },
    emits: ['close'],
    methods: {
        closeModal() {
            this.$emit('close');
        },
    },
};
</script>
<style lang="scss" scoped>
.content {
    display: flex;
    justify-content: space-evenly;
    padding: 3rem;
    gap: 1rem;
}
.left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 2rem;
    width: 65%;
}
.right {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 35%;
}
.paragraph {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    text-align: left;
}
.text-block {
    display: flex;
    flex-direction: column;
    gap: 3rem;
}
.title {
    text-align: left !important;
    @include mq($w-max-tablet, max) {
        font-size: 44px;
    }
}
.paragraph {
    color: var(--Neutral-100, #fff);
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    display: flex;
    gap: 1rem;
    flex-direction: column;
}
.green {
    color: var(--Green-300, #10ff8f);
}
.orange {
    color: var(--Yellow-500, #ffbb0b);
}
.black-text-block {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}
.kids-school-why {
    position: relative;
    top: 0;
    right: 0;
    z-index: -1;
}
.subscribe-now {
    margin: auto;
}
</style>
