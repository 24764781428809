<template>
    <div class="parent-info-modal">
        <div class="parent-info-modal-content">
            <img
                src="/images/payment-modal-v2/close_icon.png"
                alt="close"
                class="close"
                @click="closeModal"
            />
            <slot />
        </div>
    </div>
</template>
<script>
export default {
    emits: ['close'],
    methods: {
        closeModal() {
            this.$emit('close');
        },
    },
};
</script>
<style lang="scss" scoped>
.parent-info-modal {
    width: 100vw;
    width: 100dvw;
    height: 100vh;
    height: 100dvh;
    position: absolute;
    left: 0;
    top: 0;
    background: url('/images/home-game-v10/pattern.svg'),
        var(
            --Purple-soft_gradient,
            linear-gradient(180deg, #e34fd0 0%, #6e0080 100%)
        );
    background-size: 20%;
    overflow-y: hidden;
    z-index: 9999;
}
.parent-info-modal-content {
    width: 100vw;
    width: 100dvw;
    height: 100vh;
    height: 100dvh;
    overflow-y: scroll;
}
.close {
    position: absolute;
    top: 10px;
    right: 20px;
    z-index: 2;
}
</style>
