<template>
    <div class="accordion">
        <div v-for="(item, index) in data" :key="index" class="item">
            <div class="title" @click="pick(index)">
                <div class="title-content" v-html="item.title" />
                <img
                    v-if="selectedIndex !== index"
                    src="/ui/game/64/ic_baseline-plus.svg"
                    alt="plus"
                />
                <img
                    v-else
                    src="/ui/game/64/ic_baseline-minus.svg"
                    alt="minus"
                />
            </div>
            <div
                v-if="selectedIndex === index"
                class="description"
                v-html="item.description"
            />
        </div>
    </div>
</template>
<script>
export default {
    props: {
        data: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            selectedIndex: 0,
        };
    },
    methods: {
        pick(index) {
            if (index === this.selectedIndex) {
                return (this.selectedIndex = null);
            }
            this.selectedIndex = index;
        },
    },
};
</script>
<style lang="scss" scoped>
.accordion {
    display: flex;
    flex-direction: column;
    gap: 2px;
}
.item {
    display: flex;
    flex-direction: column;
    gap: 2px;
}
.title {
    border: 1px solid var(--Purple-300, #e34fd0);
    background: var(--Purple-500, #6e0080);
    padding: 1rem 2rem;
    display: flex;
    justify-content: space-between;
}
.title-content {
    color: var(--Neutral-100, #fff);
    font-family: $secondary-font-family;
    font-size: 20px;
    font-style: italic;
    font-weight: 700;
    max-width: 70%;
}

.description {
    border: 1px solid var(--Purple-300, #e34fd0);
    background: var(--Purple-700, #450c54);
    padding: 1rem 2rem;
    color: var(--Neutral-100, #fff);
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
}
</style>
